import router from "next/router";

import {requestLogout} from "utils/auth";
import {navigationController} from "utils/navigationController";
import {expand} from "utils/uri";
import {logger} from "utils/logger";

const windowUtils = {
  router: {
    navigationController,
    push: router.push,
    replace: router.replace,
  },
  user: {
    requestLogout,
  },
  uri: {
    expand,
  },
  logger: {
    error: (message, meta) => {
      logger.error(message, meta);
    },
    warn: (message, meta) => {
      logger.warn(message, meta);
    },
    info: (message, meta) => {
      logger.info(message, meta);
    },
    debug: (message, meta) => {
      logger.debug(message, meta);
    },
  },
};

export default windowUtils;
