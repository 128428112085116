
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import Head from "next/head";
import {Suspense} from "react";
import {Trans, I18nextProvider} from "react-i18next";

import {initDatadogRum, initDatadogLogs} from "utils/datadog";
import RemoteErrorBoundary from "components/errors/RemoteErrorBoundary";
import Header from "components/Header";
import Navigation from "components/Navigation";
import RemoteRibbon from "components/RemoteRibbon";
import Spinner from "components/Spinner";
import AuthProvider from "context/AuthProvider";
import ConfigProvider from "context/ConfigProvider";
import NavProvider from "context/NavProvider";
import ThemeProvider from "context/ThemeProvider";
import ToastProvider from "context/ToastProvider";
import useActiveAppStylesheets from "hooks/useActiveAppStylesheets";
import useGridAreaPositions from "hooks/useGridAreaPositions";
import useRouterSync from "hooks/useRouterSync";
import useTelemetry from "hooks/useTelemetry";
import useTheme from "hooks/useTheme";
import initBus from "eventBus/initBus";
import useAuth from "hooks/useAuth";
import useServiceWorker from "hooks/useServiceWorker";
import i18n from "utils/i18nConfig";

import styles from "./_app.module.scss";
import "./_app.global.scss";

if (typeof window !== "undefined") {
  // init XDR UI Event Bus
  initBus();

  // init datadog RUM and logs
  initDatadogRum();
  initDatadogLogs();
}

function AppContent({Component, pageProps}) {
  const {isSecureClient} = useAuth();
  const {isChangingApps} = useActiveAppStylesheets();
  const {gridAreas, registerGridArea} = useGridAreaPositions();
  const {remoteRef, syncShellRouter} = useRouterSync();
  const {theme} = useTheme();
  const remoteProps = {
    gridAreas,
    remoteRef,
    syncShellRouter,
    theme,
    ...pageProps,
  };

  return (
    <>
      <Head>
        <title>
          <Trans
            i18nKey={isSecureClient ? "app.name.secureClient" : "app.name.xdr"}
          />
        </title>
      </Head>
      <div id="app" className={styles.app}>
        <Header ref={registerGridArea("header")} />
        <Navigation ref={registerGridArea("sidebar")} />
        <div
          id="content"
          className={styles.content}
          ref={registerGridArea("content")}
        >
          <RemoteErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <div
                id="content-inner"
                className={isChangingApps ? styles.hidden : ""}
                aria-hidden={isChangingApps}
              >
                <Component {...remoteProps} />
              </div>
            </Suspense>
            {!isSecureClient
              ? !process.env.DISABLE_RIBBON && <RemoteRibbon {...remoteProps} />
              : null}
          </RemoteErrorBoundary>
        </div>
      </div>
    </>
  );
}

export default function App(props) {
  useServiceWorker();
  useTelemetry();

  return (
    <>
      <ConfigProvider>
        <AuthProvider>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider>
              <NavProvider>
                <ToastProvider>
                  <AppContent {...props} />
                </ToastProvider>
              </NavProvider>
            </ThemeProvider>
          </I18nextProvider>
        </AuthProvider>
      </ConfigProvider>
    </>
  );
}
